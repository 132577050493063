<template>
  <div
    class="diagnostic-flow-preview"
    :style="flowContainerStyles"
    ref="flowContainer"
  >
    <transition-group
      name="path"
      tag="div"
      ref="pathItems"
      @after-enter="scrollToLast"
    >
      <template v-for="(b, i) in path">
        <div
          v-if="
            !(
              (b.typeName === TYPE.Terminator && hideTerminator) ||
              hideExtConnector(b)
            )
          "
          :key="'' + i + b.key"
          :class="{
            'path-item': true,
            terminated: b.typeName === TYPE.Terminator,
            external: b.typeName === TYPE.ExtConnector,
            processed: '' + i + b.key in processedMap,
            opaque: b.isOpaque,
            ['size-' + breakpoint]: true,
            ['type-' + b.typeName.toLowerCase()]: true,
          }"
        >
          <h3 v-if="b.props && b.props.title.value">
            {{ b.props.title.value }}
          </h3>
          <p style="display: none">
            {{ b.key }}
          </p>
          <p
            v-if="
              b.props &&
              b.props.caption.value &&
              !b.props.caption.options.isInvisible
            "
            class="block-description"
            v-html="b.props.caption.value"
          />
          <div
            v-if="b.typeName === TYPE.Process || b.typeName === TYPE.Terminator"
            class="process-content"
          >
            <CustomArticle
              v-if="b.root"
              :content="b.root"
              @button-action="onProcessAction(b, $event)"
              @button-click="onButtonClick(b, $event)"
            />
          </div>
          <div
            v-if="b.typeName === TYPE.Decision"
            :class="
              'decision-buttons' +
              (b.props.align && b.props.align.value
                ? ' justify-content-' + b.props.align.value
                : '')
            "
          >
            <template
              v-if="
                b.props.branchView &&
                Number(b.props.branchView.value) ===
                  Number(DecisionBranchView.Dropdown)
              "
            >
              <b-dropdown
                :class="{ 'decision-dropdown': true, 'has-value': !!b.answer }"
                variant="link"
                @show="b.isOpaque = true"
                @hide="b.isOpaque = false"
                dropup
                no-flip
              >
                <template slot="button-content">
                  <div
                    v-if="b.answer"
                    class="selected-wrap"
                  >
                    <div class="image-wrap">
                      <img
                        v-if="b.answer && b.answer.image && b.answer.image.url"
                        :src="
                          b.answer.image.url &&
                          (b.answer.image.url + '?width=30') | cdn
                        "
                      />
                    </div>
                    <span class="label text-truncate">
                      {{ b.answer.props.title.value || '...' }}
                    </span>
                  </div>
                  <span v-else>Select an answer</span>
                </template>
                <b-dropdown-item-button
                  v-for="(branch, i) in b.root.contentBlocks"
                  :key="i"
                  @click="moveToBranch(branch, b, b.parentId)"
                  :class="{
                    'decision-dropdown-btn-wrap': true,
                    'has-image': branch.image,
                  }"
                  aria-labelledby="DropdownMenu"
                >
                  <div class="image-wrap">
                    <img
                      v-if="branch.image && branch.image.url"
                      :src="
                        branch.image.url &&
                        (branch.image.url + '?width=30') | cdn
                      "
                    />
                  </div>
                  <span class="label text-truncate">
                    {{ branch.props.title.value || '...' }}
                  </span>
                </b-dropdown-item-button>
              </b-dropdown>
            </template>
            <template v-else>
              <div
                v-for="(branch, i) in b.root.contentBlocks"
                :key="i"
                :class="{
                  'decision-button-wrap': true,
                  'has-image': branch.image,
                }"
              >
                <button
                  v-if="branch.image"
                  type="button"
                  autocomplete="off"
                  class="answer-button"
                  :aria-pressed="b.answer === branch"
                  :name="branch.props.title.value || '...' | truncateText(120)"
                  @click="moveToBranch(branch, b, b.parentId)"
                >
                  <div class="aspect aspect-4x3">
                    <div class="image-wrap aspect-content">
                      <img :src="branch.image.url | cdn" />
                      <ImageMarker
                        v-for="(m, i) in branch.image.markers"
                        :key="i"
                        :data="m"
                      />
                    </div>
                  </div>
                  <div
                    v-if="branch.props.title.value"
                    class="label-wrap"
                  >
                    <div class="radio-indicator" />
                    <div class="label">
                      {{
                        branch.props.title.value || '...' | truncateText(120)
                      }}
                    </div>
                  </div>
                </button>
                <button
                  v-else
                  type="button"
                  autocomplete="off"
                  class="answer-button btn btn-outline-primary"
                  :aria-pressed="b.answer === branch"
                  :name="branch.props.title.value || '...' | truncateText(120)"
                  @click="moveToBranch(branch, b, b.parentId)"
                >
                  <div class="radio-indicator" />
                  <span>{{ branch.props.title.value || '...' }}</span>
                </button>
              </div>
            </template>
          </div>
          <div
            v-if="b.typeName === TYPE.PredProcess && b.extRef && b.extRef.item"
            :class="{
              'guide-link-fragments': showGuideLinkFragments.length > 1,
            }"
          >
            <template v-if="checkIfPredProcessIsArticle(b)">
              <Article
                :parent-id="b.parentId"
                :item="b.extRef.item"
              />
            </template>
            <template v-if="showGuideLinkFragments.length > 1">
              <span class="process-btn-label">
                {{ showGuideLinkFragments[0] }}
              </span>
              <button
                type="button"
                class="process-btn btn btn-link"
                @click="showPredProcess(b)"
              >
                {{ ((b.extRef && b.extRef.title) || 'link') | unicodeToSymbol }}
              </button>
              <span>{{ showGuideLinkFragments[2] }}</span>
            </template>
            <template v-else>
              <button
                type="button"
                class="btn btn-link"
                @click="showPredProcess(b)"
              >
                {{ showGuideLinkFragments[0] }}
              </button>
            </template>
          </div>
          <div v-if="b.typeName === TYPE.ExtConnector">
            <h2 class="text-center">
              {{ b.extRef.title }}
            </h2>
          </div>
          <div
            v-if="
              (b.typeName === TYPE.Process ||
                b.typeName === TYPE.PredProcess) &&
              stopOnProcess
            "
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="onProcessClickNext(b, b.parentId)"
              :disabled="b.isNextDisabled"
            >
              {{ $t('buttons.step_next') }}
            </button>
          </div>
          <div v-if="b.typeName === TYPE.Terminator" />
          <div v-if="b.notFound">
            <span class="ss-alert text-warning" />
            {{ $t('errors.flow_not_found') }}
          </div>
          <div
            v-else
            class="continue-indicator"
          />
        </div>
      </template>
    </transition-group>
  </div>
</template>
<script>
import _ from 'underscore'
import filters from '~/assets/filters'
import {
  ButtonAction,
  ContentBlockType,
  DecisionBranchView,
} from '~/enums/content'
import { ImageMarker } from '~/components'
import Article from '~/components/custom/Article.vue'
import CustomArticle from '~/components/custom-article/Preview.vue'
import { Flow as TFlow } from './model'

export default {
  name: 'DiagnosticFlow',
  components: {
    ImageMarker,
    CustomArticle,
    Article,
  },
  props: {
    content: { type: [String, Object], default: '' },
    contentId: { type: [String, Number], default: '' },
    scrollBody: { type: Boolean, default: true },
    scrollOffset: { type: Number, default: -15 },
    stopOnProcess: { type: Boolean, default: false },
    hashDeeplinkEnabled: { type: Boolean, default: true },
    hideTerminator: { type: Boolean, default: false },
    restartFlow: { type: Boolean, default: false },
    container: { type: String, default: '' },
    startUrl: { type: String, default: '' },
  },
  data() {
    const showGuideLinkFragments =
      this.$t('article.show_guide_link') !== 'show_guide_link'
        ? this.$t('article.show_guide_link').split('{link}')
        : [this.$t('buttons.show')]

    // Get values for decision steps from hash
    const hash = window.location.hash
    let hashDecisions = []
    if (hash.length > 0) {
      hashDecisions = hash
        .replace('#', '')
        .split(':')
        .map((x) => parseInt(x))
    }
    const themeName = typeof CLIENT !== 'undefined' ? CLIENT.theme : 'generic'
    return {
      flow: null,
      flowContainerStyles: { paddingBottom: 0 },
      TYPE: {
        Process: 'Process',
        PredProcess: 'PredProcess',
        Decision: 'Decision',
        Branch: 'Branch',
        Terminator: 'Terminator',
        IntConnector: 'IntConnector',
        ExtConnector: 'ExtConnector',
      },
      path: [],
      loading: false,
      processedMap: {},
      madeDecisions: [],
      openedProcesses: {},
      scrollingToIndex: 0,
      showGuideLinkFragments,
      extFlows: {},
      extFlowHistory: [],
      DecisionBranchView,
      breakpoint: 'xs',
      skipInitialScroll: true, // !(this.hashDeeplinkEnabled && hashDecisions.length),
      hashDecisions,
      themeName: themeName || 'generic',
    }
  },
  created() {
    this.setFlow(this.content)
  },
  mounted() {
    this.$nextTick(() => {
      this.track('start')
    })
    this.breakpoint = this.getContainerBreakpoint()
  },
  inject: ['getContainerBreakpoint'],
  filters,
  computed: {
    contentBlocks() {
      return (this.flow && this.flow.root.contentBlocks) || []
    },
    lastDecisionHasDropdown() {
      const last = this.path[this.path.length - 1]
      return (
        last &&
        last.typeName === 'Decision' &&
        last.props.branchView &&
        last.props.branchView.value === DecisionBranchView.Dropdown
      )
    },
    notAnsweredDecisions() {
      return this.path.filter(
        (x) => x.typeName === this.TYPE.Decision && x.answer == null,
      )
    },
  },
  methods: {
    async setFlow(value) {
      const raw = typeof value === 'string' ? JSON.parse(value) : value

      TFlow.clearReferences()

      const { referenceIds } = raw
      if (referenceIds && Array.isArray(referenceIds) && referenceIds.length) {
        this.referenceIds = referenceIds

        const chuncksArr = this.referenceIds.reduce((output, item, index) => {
          const chunkIndex = Math.floor(index / 150)
          if (!output[chunkIndex]) output[chunkIndex] = [] // start a new chunk
          output[chunkIndex].push(item)

          return output
        }, [])

        await Promise.all(
          chuncksArr.map(async (chunk) => {
            try {
              const { data } = await this.$api.getBranchFlowBlocksByIds(chunk)
              if (data.length) TFlow.setReferences(data)
            } catch {}
          }),
        )
      }

      this.flow = new TFlow(raw)

      this.startFlow()
    },
    startFlow(extFlowId) {
      let blocksToParse = []
      const parentId = extFlowId || this.contentId
      let path = []
      let deepLinkQuery = ''

      deepLinkQuery = window.location.search.match(/(&|\?)start=(.+)(&|$)/)

      if (this.startUrl) {
        deepLinkQuery = this.startUrl.match(/(&|\?)start=(.+)(&|$)/)
      }

      const deepLink = deepLinkQuery && decodeURIComponent(deepLinkQuery[2])
      if (deepLink) {
        const deepLinkKey = parseFloat(window.atob(deepLink))
        blocksToParse = this.getBlocksByDeepLink(deepLinkKey, parentId)
      } else if (extFlowId && this.extFlows[extFlowId]) {
        const flowEntity = this.extFlows[extFlowId]
        blocksToParse = flowEntity.root.contentBlocks
        path = (this.path || []).slice(
          0,
          this.path.findIndex((x) => x.parentId === extFlowId),
        )
      } else {
        blocksToParse = this.contentBlocks
        path = []
      }

      this.processedMap = {}
      this.madeDecisions = []
      this.openedProcesses = {}
      this.extFlowHistory = []

      this.path = path
      this.$nextTick(() => {
        const terminateOn = null
        blocksToParse = blocksToParse || this.contentBlocks
        this.path = path.concat(
          this.traverse(blocksToParse, terminateOn, parentId),
        )
      })
    },
    traverse(blocks, terminateOn, parentId) {
      let path = []
      let terminated = false

      blocks.forEach((b) => {
        if (terminated || (terminateOn && terminateOn === b.key)) return
        b.parentId = parentId

        switch (b.typeName) {
          case this.TYPE.Process:
            path.push(b)
            if (this.hasControlNext(b)) {
              terminated = true
            }
            break
          case this.TYPE.PredProcess:
            path.push(b)
            break
          case this.TYPE.Decision:
            path.push({ ...b, answer: null, isOpaque: false })
            terminated = true
            break
          case this.TYPE.IntConnector: {
            const { parent, resultIndex } = this.findBlockByReference(
              b.ref,
              parentId,
            )
            if (parent) {
              const blocksToTraverse = parent.root.contentBlocks
                .slice(resultIndex)
                .map((b) => ({ ...b, answer: null, parentId: parentId }))
              path = path.concat(
                this.traverse(blocksToTraverse, b.key, parentId),
              )
            }
            terminated = true
            break
          }
          case this.TYPE.ExtConnector:
            path.push(b)
            terminated = true
            this.getExtContent(b, parentId).then(
              (r) => {
                if (!r) {
                  this.path = this.path.concat({ key: 'error', notFound: true })
                  return
                }
                let extBlocks = (r && r.root && r.root.contentBlocks) || []
                if (b.start && b.start.key) {
                  extBlocks =
                    this.getBlocksByDeepLink(b.start.key, b.extRef.item.id) ||
                    extBlocks
                }

                extBlocks = this.filterLoopBackConnectors(extBlocks, parentId)
                extBlocks =
                  this.traverse(extBlocks, null, b.extRef.item.id) || []
                // Filter again just in case of IntConnector leading to the loop-back ExtConnector
                extBlocks = this.filterLoopBackConnectors(extBlocks, parentId)
                extBlocks = extBlocks.map((x) => ({
                  ...x,
                  parentId: b.extRef.item.id,
                }))

                // Modify this.path and not pass, because async
                this.path = this.path.concat(extBlocks)
              },
              () => {
                this.path = this.path.concat({ key: 'error', notFound: true })
              },
            )
            break
          case this.TYPE.Terminator:
            path.push(b)
            terminated = true
            // Trigger a finish only when a view is updated
            this.$nextTick(() => {
              this.track('finish', b)
            })
            break
          default:
            terminated = true
        }
      })

      this.$emit(
        'flow-terminated',
        path.length && path[path.length - 1].typeName === 'Terminator',
      )

      return path
    },
    traverseFrom(refKey, terminateOn, parentId, skipStart) {
      let path = []
      if (refKey) {
        const { parent, resultIndex } = this.findBlockByReference(
          refKey,
          parentId,
        )
        const startIndex = skipStart ? resultIndex + 1 : resultIndex
        if (parent) {
          const blocksToTraverse = parent.root.contentBlocks
            .slice(startIndex)
            .map((b) => ({ ...b, answer: null, parentId: parentId }))
          path = path.concat(
            this.traverse(blocksToTraverse, terminateOn, parentId),
          )
        }
      }
      return path
    },
    async moveToBranch(branch, decision, parentId) {
      if (branch.root.isEmpty() && branch.id) {
        let root = TFlow.getReferenceById(branch.id)
        if (!root) {
          const { data } = await this.$api.getBranchFlowBlocksByIds([branch.id])
          TFlow.setReferences(data)
          root = TFlow.getReferenceById(branch.id)
        }

        if (root) branch.setRoot(root)
      }

      decision.answer = branch

      const index = this.madeDecisions.indexOf(decision)
      if (index !== -1) {
        this.madeDecisions.splice(index)
        this.path.splice(this.path.indexOf(decision))
        this.path.push(decision)
      }
      this.madeDecisions.push(decision)
      this.updateProcessedBlocksMap(this.path)

      this.path = this.path.concat(
        this.traverse(branch.root.contentBlocks, null, parentId),
      )

      if (this.hashDeeplinkEnabled) {
        const decisions = []
        this.path.forEach((x) => {
          if (x.typeName === this.TYPE.Decision && x.answer) {
            const branchIndex = x.root.contentBlocks.findIndex(
              (b) => b.key === x.answer.key,
            )
            if (branchIndex >= 0) decisions.push(branchIndex + 1)
          }
        })
        var hash = `#${decisions.join(':')}`
        window.location.hash = hash
      }

      this.track('decision', decision)
    },
    updateProcessedBlocksMap(path) {
      this.processedMap = {}
      path.map((x, i) => (this.processedMap['' + i + x.key] = true))
    },
    updateMadeDecisions(path) {
      const lastBlock = path && path[path.length - 1]
      const lastDecision = this.madeDecisions[this.madeDecisions.length - 1]
      if (
        lastBlock &&
        lastBlock.typeName === 'Decision' &&
        lastDecision === lastBlock
      ) {
        this.madeDecisions.splice(this.madeDecisions.length - 1, 1)
        lastDecision.answer = null
      }
    },
    scrollToEl(el) {
      const offset = this.scrollOffset

      this.$scrollTo(
        el,
        300,
        this.scrollBody
          ? { offset, easing: 'ease' }
          : {
              offset,
              container: this.container
                ? this.container
                : '.diagnostic-flow-preview',
            },
      )
    },
    scrollToLast: _.debounce(
      function (scrollToEl) {
        this.adjustPaddingForScroll()
        if (this.skipInitialScroll) {
          this.skipInitialScroll = false
        } else {
          this.$nextTick(() => {
            this.scrollToEl(scrollToEl)
          })
        }
      },
      500,
      true,
    ),
    showPredProcess(process) {
      this.$root.$emit('app::contentPreviewModal::preview', {
        item: process.extRef.item,
        parentId: process.parentId || this.contentId,
      })
    },
    async getExtContent(node, parentId) {
      let flow = {}
      if (this.extFlows[node.extRef.item.id]) {
        flow = this.extFlows[node.extRef.item.id]
      } else {
        try {
          const contentId = node.extRef.item.id
          const resp = await (parentId
            ? this.$api.getRelatedContentById(parentId, contentId)
            : this.$api.getContentById(contentId))
          flow = JSON.parse(
            (resp && resp.data && resp.data.headRevision.content.Html) || null,
          )
          this.extFlows[node.extRef.item.id] = flow
        } catch (e) {
          console.error(
            "SP Content: can't get article: ",
            node &&
              node.extRef &&
              node.extRef.item &&
              `${node.extRef.item.id} - ${node.extRef.item.title}`,
          )
        }
      }
      return flow
    },
    hasControlNext(b) {
      if (b.typeName !== 'Process') return false
      const buttonBlocks = b.root.contentBlocks.filter(
        (x) => x.typeName === ContentBlockType.Button,
      )
      const dfActions = [ButtonAction.Next]
      return (
        buttonBlocks
          .reduce((acc, cur) => acc.concat(cur.buttons), [])
          .filter((x) => x.action && dfActions.indexOf(x.action.type) !== -1)
          .length > 0
      )
    },
    onProcessAction(block, action) {
      switch (action.type) {
        case ButtonAction.Next:
          if (this.path.indexOf(block) === this.path.length - 1) {
            const subpath = this.traverseFrom(
              block.key,
              null,
              block.parentId,
              true,
            )
            block.isNextDisabled = true
            this.updateProcessedBlocksMap(this.path)
            this.path = this.path.concat(subpath)
          }
          break
        case ButtonAction.Prev: {
          const prevPath = [...this.path].reverse()
          const findPath = prevPath.slice(prevPath.indexOf(block))
          const prevBlock = findPath.find(
            (x) =>
              x.typeName === 'Decision' ||
              (this.hasControlNext(x) && x.key !== block.key),
          )
          if (prevBlock) {
            // Cut everything after a prev block
            this.path.splice(this.path.indexOf(prevBlock) + 1)
            this.updateProcessedBlocksMap(
              this.path.slice(0, this.path.length - 1),
            )
            this.updateMadeDecisions(this.path)
          }
          break
        }
        case ButtonAction.Restart:
          this.startFlow(block.parentId)
          break
      }
    },
    onButtonClick(block, action) {
      switch (action.type) {
        case ButtonAction.URL:
        case ButtonAction.JS:
          this.track('button-click', action)
          break
        case ButtonAction.Next:
          this.track('decision-next-click', action)
          break
        case ButtonAction.Prev:
          this.track('decision-prev-click', action)
          break
      }
    },
    findBlockByReference(ref, parentId, subtreeRoot) {
      const root = (parentId && this.extFlows[parentId]) || {
        root: subtreeRoot || { contentBlocks: this.contentBlocks },
      }
      const problem = {
        getRoot: () => {
          return root
        },
        isGoal: (found) => {
          return found && found.key === ref
        },
        getSuccessors: (subtreeRoot) => {
          return subtreeRoot && subtreeRoot.root
            ? subtreeRoot.root.contentBlocks
            : []
        },
      }

      const pathToItem = this.BFSIterative(problem)

      if (!pathToItem) return {}

      let parent = root
      let result = root
      let resultIndex = 0

      pathToItem &&
        pathToItem.forEach((childIndex) => {
          parent = result
          result = result.root.contentBlocks[childIndex]
          resultIndex = childIndex
        })

      return { parent, result, resultIndex }
    },
    filterLoopBackConnectors(blocks, parentId) {
      const loopConnector = blocks.find((x) => {
        if (x.typeName === this.TYPE.ExtConnector) {
          // ExtRef shouldn't point to the parent or initial flow
          if (
            x.extRef.item.id === parentId ||
            x.extRef.item.id === this.contentId
          ) {
            return true
          }
        }
      })

      if (loopConnector) {
        const index = blocks.indexOf(loopConnector)
        blocks.splice(index, 1, { loop: true, key: 'loop' })
      }

      return blocks
    },
    getBlocksByDeepLink(deepLinkKey, parentId) {
      deepLinkKey = parseFloat(deepLinkKey)
      if (deepLinkKey) {
        const { parent, resultIndex } = this.findBlockByReference(
          deepLinkKey,
          parentId,
        )
        if (parent && parent.root && parent.root.contentBlocks) {
          return parent.root.contentBlocks.slice(resultIndex)
        }
      }
    },
    BFSIterative(problem) {
      const openSet = []
      const closedSet = new Set()
      const meta = new Map()

      const root = problem.getRoot()
      meta.set(root, null)
      openSet.push(root)

      while (openSet.length) {
        const subtreeRoot = openSet.shift()

        if (problem.isGoal(subtreeRoot)) {
          return this.constructPath(subtreeRoot, meta)
        }

        problem.getSuccessors(subtreeRoot).forEach((child, action) => {
          if (closedSet[child]) return

          if (openSet.indexOf(child) === -1) {
            meta.set(child, [subtreeRoot, action])
            openSet.push(child)
          }
        })

        closedSet.add(subtreeRoot)
      }
    },
    constructPath(root, meta) {
      const actionList = []
      let state = root
      while (true) {
        const row = meta.get(state)

        if (row && row.length === 2) {
          state = row[0]
          const action = row[1]
          actionList.push(action)
        } else {
          break
        }
      }
      return actionList.reverse()
    },
    track(dfEvent, data) {
      const eventData = {
        type: 'event',
      }
      switch (dfEvent) {
        case 'start':
          eventData.action = 'diagnostic_flow_start'
          break
        case 'decision':
          eventData.action = 'decision_made'
          eventData.label = data.answer && data.answer.props.title.value
          break
        case 'finish':
          eventData.action = 'diagnostic_flow_finish'
          eventData.label = data.props.title.value
          break
        case 'button-click':
          eventData.action = 'button-click'
          eventData.label = data.value
          break
        case 'decision-next-click':
          eventData.action = 'decision-next-click'
          eventData.label = data.value
          break
        case 'decision-prev-click':
          eventData.action = 'decision-prev-click'
          eventData.label = data.value
          break
      }

      this.$emit('track', eventData)
    },
    adjustPaddingForScroll() {
      if (this.lastDecisionHasDropdown) {
        this.flowContainerStyles.paddingBottom = 200 + 'px'
      } else {
        this.flowContainerStyles.paddingBottom = '20px'
      }
    },
    // Implement render article inside DF
    checkIfPredProcessIsArticle(process) {
      const item = process.extRef.item || {}
      return item.contentType === 'Article'
    },
    hideExtConnector(b) {
      if (this.themeName !== 'o2') return false // hide this because for o2 we don't need to show that block, and we can not use styles because scrollTo after that doesn't work
      return b.typeName === this.TYPE.ExtConnector
    },
  },
  watch: {
    notAnsweredDecisions(array) {
      if (array.length && this.hashDecisions.length) {
        const decision = array[0]
        const branch = decision.root.contentBlocks[this.hashDecisions[0] - 1]

        if (branch) {
          this.moveToBranch(branch, decision, decision.parentId)

          // Remove first element from array
          this.hashDecisions.splice(0, 1)
        } else {
          this.hashDecisions = []
        }
      }
    },
    async restartFlow(val) {
      if (val && this.contentId) {
        await this.startFlow(this.contentId)
        this.skipInitialScroll = true
        this.$nextTick(() => {
          setTimeout(() => {
            this.skipInitialScroll = false
            const offset = 0
            this.$scrollTo('.sp-content-module', 300, {
              offset,
              easing: 'ease',
            })
          }, 500)
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .diagnostic-flow-preview {
    position: relative;
    padding: 1em 0 0;
    width: 100%;
    overflow: hidden;
    .path-item {
      transition: all 0.2s;
      position: relative;
      opacity: 1;
      padding: 0.5em 0 3em 1em;
      margin: 0;
      margin-left: 0.5em;
      border-radius: 0.125rem;
      border-left: 1px solid $accent-color;

      .primary-outline {
        color: $accent-color;
      }

      > h3 {
        font-size: 16px;
        color: $header-color;
        font-weight: bold;
        margin-top: -0.5em;
        margin-bottom: 0.25em;
      }

      > p {
        margin-bottom: 0.5em;
      }

      .block-description {
        font-style: italic;
      }

      &::after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        left: -4px;
        top: 0;
        background-color: $accent-color;
        border-radius: 4px;
        opacity: 1;
      }

      &:first-child,
      &.external {
        margin-top: 0;

        &::after {
          width: 14px;
          height: 8px;
          margin-left: -3px;
        }
      }

      &:last-child {
        margin-bottom: 0;
        &::before {
          background-color: transparent;
          border-left: 1px dashed $accent-color;
        }

        .continue-indicator {
          width: 8px;
          height: 8px;
          position: absolute;
          left: -4px;
          bottom: 0;
          margin-bottom: -8px;
          background-color: #fff;
          border: 1px solid $accent-color;
          border-radius: 0.5em;
        }
      }

      &.processed {
        border-color: $border-color;
        opacity: 0.5;
        transition: all 0.2s ease-out;

        &::after,
        &::before {
          background-color: $border-color;
        }

        &:hover {
          opacity: 1;
        }

        &.opaque {
          opacity: 1;
          position: relative;
          z-index: 1;
        }
      }

      &.terminated {
        border: none;
        padding-bottom: 0.5em;

        &::after {
          width: 14px;
          height: 14px;
          left: -6px;
          top: 0;
          border-radius: 7px;
          background-color: $accent-color;
          background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2064%2064%22%3E%3Cpath%20stroke%3D%22%23ffffff%22%20stroke-width%3D%2212%22%20fill%3D%22none%22%20d%3D%22M6%2040%20L24%2056%20L58%2012%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E);
          background-size: 70%;
          background-repeat: no-repeat;
          background-position: center;
        }

        .continue-indicator {
          display: none;
        }
      }

      // Block breakpoints. Relies on a container width and not a screen width
      &.size-xs {
      }
      &.size-sm {
        .decision-button-wrap {
          flex-basis: 50%;
        }
      }
      &.size-md {
        .decision-button-wrap {
          flex-basis: 50%;
        }
      }
      &.size-lg {
        .decision-button-wrap {
          flex-basis: 33.3%;
        }
      }
      &.size-xl {
        .decision-button-wrap {
          flex-basis: 33.3%;
        }
      }

      .process-btn-label {
        vertical-align: middle;
      }
      .process-btn {
        word-break: break-all; // IE fix
        word-break: break-word;
        white-space: normal;
      }
      .decision-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 -15px;

        button {
          max-width: 100%;
          width: 100%;
          word-break: break-all; // IE fix
          word-break: break-word;
          overflow: hidden;
          @media screen and (min-width: 768px) {
            min-width: 220px;
          }
        }

        &.align-buttons {
          &-start {
            justify-content: flex-start;
          }
          &-center {
            justify-content: center;
          }
          &-end {
            justify-content: flex-end;
          }
          &-justify {
            .decision-button-wrap {
              flex-grow: 1;

              .answer-button {
                width: 100%;
              }
            }
          }
        }
      }
      .decision-button-wrap {
        flex: 0 1 100%;
        padding: 10px;
        max-width: 100%;
      }

      .answer-button {
        font-size: $btn-font-size;
        position: relative;
        width: 100%;
        white-space: normal;
        // text-overflow: inherit;

        // box-shadow: 0 1px 6px 0 rgba(0,0,0,.2);
        // transition: box-shadow .5s ease-in-out;
        // font-size: 18px;
        // font-weight: bold;
        // color: $accent-color;
        // background-color: transparent;
        // padding: 20px;

        &.active,
        &[aria-pressed='true'] {
          background-color: $accent-color;
          color: $white;
          .label {
            color: $white;
            background-color: $accent-color;
          }
        }

        &:focus {
          outline: none;
        }

        .radio-indicator {
          display: none;
        }
      }

      .decision-button-wrap.has-image {
        .image-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 15px 15px;
        }
        .answer-button {
          position: relative;
          background-color: transparent;
          border-radius: 15px;
          padding: 0;
          &.active,
          &[aria-pressed='true'] {
            border-color: $accent-color;
            .label-wrap {
              background-color: $accent-color;
            }
          }
          img {
            max-width: 100%;
            max-height: 100%;
            transition: transform 0.5s ease-in-out;
          }
          &.active,
          &[aria-pressed='true'],
          &:hover {
            img {
              transform: scale(1.1);
            }
          }

          &:hover {
            border-color: $accent-color;
          }
        }

        .label-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 3.5rem;
          padding: 0 10px 0;
        }

        .label {
          font-size: 18px;
          display: block;
          width: 100%;
          max-height: 100%;
          word-wrap: break-word;
          overflow: hidden;
          padding: 0 10px;
        }
      }

      .decision-dropdown {
        $height: 45px;
        $border-radius: $height/2;

        margin: 0 15px;
        flex-grow: 1;
        height: $height;
        color: $primary;
        border-color: $primary; // for inherit by children

        @media screen and (min-width: 768px) {
          min-width: 50%;
          flex-grow: 0;
        }
        @media screen and (min-width: 1200px) {
          min-width: 33.3%;
          flex-grow: 0;
        }
        .dropdown-toggle {
          width: 100%;
          padding-right: 1.5rem;
          text-align: left;
          border-radius: $border-radius;
          border: 1px solid;
          border-color: inherit;
          color: inherit;
          text-decoration: none;
          &::after {
            position: absolute;
            top: 50%;
            right: 1rem;
            color: $body-color;
          }
        }

        .dropdown-menu {
          width: 100%;
          max-height: 120px;
          overflow-y: auto;
          border: 1px solid;
          border-color: inherit;
          border-top: none;
          margin-top: 0;
          border-radius: 0;
          border-bottom-left-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
          color: inherit;

          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-track {
            margin-bottom: 16px;
            background-color: white;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $text-secondary;
            border-radius: 4px;
            padding-bottom: 10px;
          }
        }

        .selected-wrap {
          display: flex;
          max-width: 100%;
        }

        &.show {
          .dropdown-toggle {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-color: $border-color;

            &::after {
              transform: rotate(180deg);
            }
          }
        }

        &.has-value {
          .dropdown-toggle {
            background-color: $accent-color;
            color: $white;
            border-bottom-color: $accent-color;
            &::after {
              color: white;
            }
          }
        }

        .dropdown-item {
          display: flex;
          padding-left: 0.75rem;
          color: inherit;

          &:focus,
          &:active {
            outline: none;
            background-color: #eee;
          }
        }

        $width: 40px;
        $height: $width/4 * 3;

        .image-wrap {
          width: $width;
          height: $height;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 2px;
          margin-right: 0.5em;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .label {
          height: $height;
          display: block;
          line-height: $height;
        }
      }

      .process-content {
        margin: 15px 0;
      }

      &.external {
        padding-bottom: 2em;
        > h3 {
          // display: none;
        }
        &::before {
          content: '';
          display: block;
          border-top: 1px dashed $accent-color;
          opacity: 0.5;
          margin: -0.25em 0 2em;
        }
      }

      &.type-predprocess .custom-article-render {
        display: none;
      }
    }
    // Transitions
    .path-enter {
      opacity: 0;
      z-index: 1;
      transform: translateX(50px);

      .path-item {
        &::before,
        &::after {
          opacity: 0;
        }
      }
    }

    .path-enter-active {
      transition-delay: 0.2s;

      .path-item {
        &::before,
        &::after {
          transition: all 0.2s ease-out;
          transition-delay: 0.5s;
        }
      }
    }

    .path-leave-to {
      opacity: 0;
      transform: translate(-50px);
    }
  }
}
</style>
